<template>
    <div class="p-b-10">
        <div class="platform-buttom-list">
            <div :class="tagType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">资源清单</div>
            <div :class="tagType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">售卖时间表</div>
        </div>
        <search :showSaleTimeline="showSaleTimeline" @parentMethod="handleChildSearch" @parentExport="handleExport"/>
        <resourceList :list="list" :isManage="isManage" :categoryId="categoryId" :showSaleTimeline="showSaleTimeline"
          :startDate="startDate" :endDate="endDate"/>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator @on-change="handleChangePage"></Page>
        </div>
    </div>
</template>

<script>
import search from '../bottomComponents/Search'
import resourceList from '../bottomComponents/List'
// apis
import { getResourcePage, startDownloadResources } from '@/api/product/productresource'
// utils
import { downloadFileRequest } from '@/utils/download'
import { ParseDate } from '@/utils/dateFormat'

export default {
  components: {
    search, resourceList
  },
  data () {
    return ({
      tagType: 1,
      sign: '',
      showSaleTimeline: false, // 显示售卖时间轴

      query: {
        assetIds: '',
        stationId: null,
        stationType: null,
        productIds: null,
        resourcetypeIds: '',
        productSkuId: null,
        position: null,
        soldStatus: [],
        orderTypes: [],
        stationIds: '',
        enabled: 1,
        schedules: '',
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      startDate: '',
      endDate: '',
      list: [],
      total: 0
    })
  },
  methods: {
    initPageData (stationId) {
      this.query = {
        assetIds: this.searchCondition.assetIds,
        stationId: stationId || null,
        stationType: null,
        productId: this.searchCondition.productId,
        productIds: this.searchCondition.productIds,
        resourcetypeIds: this.searchCondition.resourcetypeIds,
        productSkuId: null,
        position: null,
        soldStatus: [],
        orderTypes: [],
        stationIds: '',
        enabled: 1,
        schedules: this.searchCondition.schedules,
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      }

      this.startDate = ParseDate(this.searchCondition.startDate)
      this.endDate = ParseDate(this.searchCondition.endDate)

      this.handleSearch()
    },
    handleChangeTag (val) {
      this.tagType = val
      this.showSaleTimeline = (val !== 1)
      this.handleSearch()
    },
    handleSearch () {
      getResourcePage(this.query).then(res => {
        if (res) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber === 0 ? 1 : res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleChangePage (page) {
      this.query.pageNumber = page
      this.handleSearch()
    },
    handleChildSearch (data) {
      this.query.stationId = data.stationId
      this.query.position = data.position
      this.query.orderTypes = JSON.stringify(data.orderTypes)
      this.query.soldStatus = JSON.stringify(data.soldStatus)
      this.query.enabled = data.enabled
      this.query.keyword = data.keyword
      this.query.pageNumber = 1

      this.handleSearch()
    },
    handleExport (data) {
      this.query.stationId = data.stationId
      this.query.position = data.position
      this.query.orderTypes = data.orderTypes
      this.query.soldStatus = data.soldStatus
      this.query.enabled = data.enabled
      this.query.keyword = data.keyword
      this.sign = ''

      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h('Icon', {
              class: 'spin-icon-load',
              props: {
                type: 'ios-loading',
                size: 24
              }
            }),
            h('div', '数据生成中...')
          ])
        }
      })

      this.fileDownload(this.query)
    },
    fileDownload (params) {
      startDownloadResources(params).then(res => {
        if (res && !res.errcode) {
          this.sign = res.sign
          if (res.status === 2) {
            this.$Spin.hide()
            const filename = '资源库存清单.xlsx'
            downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-product/v1/productresource/downloadResourcesExcel', { sign: this.sign }, filename)
          } else {
            setTimeout(() => {
              this.fileDownload(Object.assign({}, params, { sign: this.sign }))
            }, 3000)
          }
        } else {
          this.$Spin.hide()
        }
      }).catch(() => {
        this.$Spin.hide()
        this.$Notice.error({ desc: '发生一个异常' })
      })
    }
  },
  computed: {
    isManage () {
      return this.$store.state.stock.isManage
    },
    beginUpdate () {
      return this.$store.state.stock.beginUpdate
    },
    mapType () {
      return this.$store.state.stock.mapType
    },
    stations () {
      return this.$store.state.stock.stations
    },
    searchCondition () {
      return this.$store.state.stock.searchCondition
    },
    categoryId () {
      return this.$store.state.stock.categoryId
    }
  },
  watch: {
    beginUpdate (val) {
      let stationId = null
      // 显示全部资源
      if (this.mapType === 'resource') {
        this.query.stationIds = JSON.stringify(this.stations.map(x => x.stationId))
      } else if (this.mapType === 'flicker') { // 闪烁显示单个站点
        stationId = this.stations[0]
      }
      this.initPageData(stationId)
    }
  }
}
</script>
